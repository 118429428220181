<template>
  <KModal
    :show.sync="showModal"
    :no-close-on-backdrop="true"
    :centered="true"
    title="Progress Dialog"
    color="info"
    size="sm"
  >

    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <h6>{{message}}</h6>
          <CProgress :value="counter" :max="max" show-percentage animated></CProgress>
        </div>
      </div>
    </div>

    <template #header>
      <h6 class="modal-title text-center">{{title}}</h6>
    </template>
    <template #footer-wrapper>
      <!-- empty footer -->
      <div></div>
    </template>

  </KModal>
</template>

<script>
export default {
  name: 'Progress',
  props: {
  },
  data() {
    return {
      showModal: false,
      title: '엑셀 저장',
      message: '요청 데이터를 저장중입니다.',
      counter: 100,
      max: 100
    }
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    show({ message, callback, title, counter=100, max=100, timeout=1000 } = {})  {
      var self = this;
      if (message) this.message = message;
      if (title) this.title = title;
      this.counter = counter;
      this.max = max;
      setTimeout(function() {
        self.showModal = false;
        if (callback) callback()
      }, timeout)
      this.showModal = true;
    }
  }
}
</script>